import { Select } from '@/web/components/Select'
import { TimePicker } from '@/web/components/TimePicker/TimePicker'
import { Button, type ButtonProps } from '@/web/components/ui/button'
import { Calendar, type CalendarProps } from '@/web/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/web/components/ui/popover'
import { cn } from '@/web/libs/utils'
import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import {
  useNavigation,
  type CaptionProps,
  type SelectSingleEventHandler,
} from 'react-day-picker'

function CustomCaption(props: CaptionProps) {
  const { goToMonth } = useNavigation()
  const months = Array.from({ length: 12 }, (_, i) =>
    format(new Date(2022, i), 'MMMM')
  )
  return (
    <div className='flex flex-row justify-center gap-2'>
      <Select
        options={months.map((month, index) => ({
          value: index.toString(),
          label: month,
        }))}
        value={props.displayMonth.getMonth().toString()}
        onChange={selectedMonthIndex => {
          const month = Number(selectedMonthIndex)
          const newDate = new Date(props.displayMonth.getFullYear(), month)
          goToMonth(newDate)
        }}
        placeholder='Month'
        size='xs'
        buttonProps={{
          className: 'flex-1',
        }}
      />
      <Select
        options={Array.from(
          { length: 50 },
          (_, i) => new Date().getFullYear() - i
        ).map(year => ({ value: year.toString(), label: year.toString() }))}
        value={props.displayMonth.getFullYear().toString()}
        onChange={selectedYear => {
          const year = Number(selectedYear)
          const newDate = new Date(year, props.displayMonth.getMonth())
          goToMonth(newDate)
        }}
        placeholder='Select a year'
        size='xs'
        buttonProps={{
          className: 'flex-1',
        }}
      />
    </div>
  )
}

type DatePickerProps = Partial<CalendarProps> & {
  value?: Date | null
  onChange: (date: Date) => void
  onBlur?: () => void
  buttonProps?: Partial<ButtonProps>
  isShowingTime?: boolean
}
export function DatePicker({
  value,
  onChange,
  onBlur,
  buttonProps,
  isShowingTime: useTime,
  ...calendarProps
}: DatePickerProps) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          theme='secondary'
          className={cn(
            'pl-3 text-left font-normal',
            !value && 'text-muted-foreground'
          )}
          startIcon={<CalendarIcon className='h-3 w-auto' />}
          {...buttonProps}>
          {value ? (
            format(value, useTime ? 'PPPp' : 'PPP')
          ) : (
            <span>Pick a date{useTime ? ' and time' : ''}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0' align='start'>
        <Calendar
          mode='single'
          selected={value ?? undefined}
          defaultMonth={value ?? undefined}
          // FIXME: this is a bug in react-day-picker
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onSelect={
            ((day: Date | undefined) => {
              if (day) {
                onChange(day)
              }
            }) as SelectSingleEventHandler
          }
          onDayBlur={() => {
            onBlur?.()
          }}
          components={{
            Caption: CustomCaption,
          }}
          {...calendarProps}
        />
        {useTime ? (
          <div className='border-border flex justify-between border-t p-3'>
            <TimePicker setDate={onChange} date={value ?? undefined} />
            <Button
              variant='outline'
              theme='secondary'
              className='ml-auto'
              size='xs'
              onClick={() => onChange(new Date())}>
              Now
            </Button>
          </div>
        ) : null}
      </PopoverContent>
    </Popover>
  )
}
