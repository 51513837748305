'use client'

import * as React from 'react'
import { TimePeriodSelect } from './TimePeriodSelect'
import { TimePickerInput } from './TimePickerInput'
import { type Period } from './TimePickerUtils'

interface TimePickerDemoProps {
  date: Date | undefined
  setDate: (date: Date) => void
}

export function TimePicker({ date, setDate }: TimePickerDemoProps) {
  const [period, setPeriod] = React.useState<Period>('PM')

  const minuteRef = React.useRef<HTMLInputElement>(null)
  const hourRef = React.useRef<HTMLInputElement>(null)
  const secondRef = React.useRef<HTMLInputElement>(null)
  const periodRef = React.useRef<HTMLButtonElement>(null)

  return (
    <div className='flex items-center justify-center'>
      <TimePickerInput
        picker='12hours'
        period={period}
        date={date}
        setDate={setDate}
        ref={hourRef}
        onRightFocus={() => minuteRef.current?.focus()}
      />
      :
      <TimePickerInput
        picker='minutes'
        id='minutes12'
        date={date}
        setDate={setDate}
        ref={minuteRef}
        onLeftFocus={() => hourRef.current?.focus()}
        onRightFocus={() => secondRef.current?.focus()}
      />
      {/* <TimePickerInput
        picker='seconds'
        id='seconds12'
        date={date}
        setDate={setDate}
        ref={secondRef}
        onLeftFocus={() => minuteRef.current?.focus()}
        onRightFocus={() => periodRef.current?.focus()}
      /> */}
      <TimePeriodSelect
        period={period}
        setPeriod={setPeriod}
        date={date}
        setDate={setDate}
        ref={periodRef}
        onLeftFocus={() => secondRef.current?.focus()}
      />
    </div>
  )
}
