import { cn } from '@/web/libs/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

const ghostNoHoverClasses =
  'focus-visible:ring-0 focus-visible:ring-offset-0 bg-transparent rounded-none focus:ring-0 focus:ring-offset-0 border-transparent [border-style:solid] border'
const rootVariants = cva(
  'flex w-full rounded-md border border-input bg-background text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 caret-primary selection:bg-primary/25',
  {
    variants: {
      variant: {
        default: '',
        ghost: `${ghostNoHoverClasses} hover:bg-input/10 hover:border-border`,
        ghostNoHover: ghostNoHoverClasses,
        transparent:
          'bg-transparent border-transparent border-0 focus-visible:ring-0 focus-visible:ring-offset-0',
      },
      size: {
        none: '',
        xs: 'h-6 px-2 py-1 text-xs',
        sm: 'h-8 px-2 py-1.5 text-sm',
        default: 'h-10 px-3 py-2',
        lg: 'h-12 px-4 py-0 text-lg',
        xl: 'h-[3.4rem] rounded-lg px-12 w-auto py-2 text-lg text-center',
        '2xl': 'h-20 px-8 py-0 text-2xl',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
    compoundVariants: [
      {
        variant: 'ghost',
        className: 'rounded-none border-none',
      },
    ],
  }
)

export type InputProps = VariantProps<typeof rootVariants> &
  Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    keyof VariantProps<typeof rootVariants>
  >

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, type, size, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(rootVariants({ variant, size }), className)}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = 'Input'

export { Input }
