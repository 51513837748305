import { cn } from '@/web/libs/utils'
import { cva, type VariantProps } from 'class-variance-authority'
import React, { useEffect, useImperativeHandle, useRef } from 'react'

const ghostNoHoverClasses =
  'focus-visible:ring-0 focus-visible:ring-offset-0 bg-transparent rounded-none focus:ring-0 focus:ring-offset-0 border-transparent [border-style:solid] border'
const rootVariants = cva(
  'flex w-full rounded-sm border border-input bg-background px-3 py-2 ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: '',
        ghost: `${ghostNoHoverClasses} hover:bg-input/10 hover:border-border active:bg-input/10 active:border-border focus:bg-input/10 focus:border-border`,
        ghostNoHover: ghostNoHoverClasses,
        form: 'rounded-md',
        transparent:
          'bg-transparent border-transparent focus-visible:ring-0 focus-visible:ring-offset-0',
      },
      theme: {
        default: '',
        secondary: 'text-secondary-foreground',
      },
      size: {
        default: 'text-sm px-3 py-2',
        sm: 'text-sm px-3 py-1.5',
        lg: 'px-4 py-0 text-lg leading-snug',
        xl: 'rounded-lg px-12 w-auto py-2 text-lg text-center',
        none: 'self-center text-left px-0 w-full py-0',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
    compoundVariants: [
      {
        variant: 'ghost',
        className: 'rounded-none border-none',
      },
      {
        variant: 'ghost',
        theme: 'secondary',
        className: 'hover:bg-white active:bg-white focus:bg-white',
      },
    ],
  }
)

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  VariantProps<typeof rootVariants> & {
    isAutoResizing?: boolean
  }

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { className, variant, size, theme, isAutoResizing = true, ...props },
    ref
  ) => {
    const resizeToFitContent = (textAreaElement: HTMLTextAreaElement) => {
      if (
        !isAutoResizing ||
        textAreaElement.style.height === `${textAreaElement.scrollHeight}px`
      )
        return

      // Reset the height to 'auto' to ensure that the content dictates the size, not the other way around.
      const originalHeight = textAreaElement.style.height
      textAreaElement.style.height = '0px'

      // Now, set the height to the scrollHeight, which represents the content's actual height.
      textAreaElement.style.height = `${textAreaElement.scrollHeight}px`

      console.debug(
        'Resizing text area height to %opx with content %o (previously %o)',
        textAreaElement.scrollHeight,
        textAreaElement.value,
        originalHeight
      )
    }

    // Create a local ref
    const localRef = useRef<HTMLTextAreaElement>(null)

    // If a ref is forwarded, make sure it also gets the current value of the local ref
    useImperativeHandle<HTMLTextAreaElement | null, HTMLTextAreaElement | null>(
      ref,
      () => localRef.current
    )

    // Call resizeToFitContent on mount and input events
    useEffect(() => {
      if (!isAutoResizing) return

      if (localRef?.current) {
        console.debug('Resizing text area on mount or input')
        resizeToFitContent(localRef?.current)
      }
    }, [localRef?.current?.value])

    useEffect(() => {
      if (!isAutoResizing || !localRef.current) return

      const handleWindowResize = () => {
        if (!localRef.current) return
        console.debug('Window resize event: resizing text area')
        resizeToFitContent(localRef.current)
      }

      // Add event listener for window resize
      window.addEventListener('resize', handleWindowResize)

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleWindowResize)
    }, [localRef, isAutoResizing])

    return (
      <textarea
        className={cn(
          rootVariants({ variant, size, theme }),
          isAutoResizing && 'resize-none overflow-y-clip',
          className
        )}
        ref={localRef}
        data-lpignore='true'
        {...props}
        onInput={e => {
          if (isAutoResizing) {
            resizeToFitContent(e.currentTarget as HTMLTextAreaElement)
          }

          if (props.onInput) props.onInput(e)
        }}
      />
    )
  }
)
Textarea.displayName = 'Textarea'

export { Textarea }
